export const GermanCity = {
  Aachen: 'Aachen',
  Augsburg: 'Augsburg',
  BergischGladbach: 'BergischGladbach',
  BadHomburg: 'BadHomburg',
  Berlin: 'Berlin',
  Bielefeld: 'Bielefeld',
  Bochum: 'Bochum',
  Bonn: 'Bonn',
  Bottrop: 'Bottrop',
  Braunschweig: 'Braunschweig',
  Bremen: 'Bremen',
  Bremerhaven: 'Bremerhaven',
  Chemnitz: 'Chemnitz',
  Cologne: 'Cologne',
  Darmstadt: 'Darmstadt',
  Dortmund: 'Dortmund',
  Dresden: 'Dresden',
  Duisburg: 'Duisburg',
  Dusseldorf: 'Dusseldorf',
  Erfurt: 'Erfurt',
  Erlangen: 'Erlangen',
  Essen: 'Essen',
  Freiburg: 'Freiburg',
  Frankfurt: 'Frankfurt',
  Furth: 'Furth',
  Gelsenkirchen: 'Gelsenkirchen',
  Gottingen: 'Gottingen',
  Gutersloh: 'Gutersloh',
  Hagen: 'Hagen',
  Halle: 'Halle',
  Hamburg: 'Hamburg',
  Hamm: 'Hamm',
  Hanover: 'Hanover',
  Heidelberg: 'Heidelberg',
  Heilbronn: 'Heilbronn',
  Herne: 'Herne',
  Hildesheim: 'Hildesheim',
  Ingolstadt: 'Ingolstadt',
  Jena: 'Jena',
  Karlsruhe: 'Karlsruhe',
  Kassel: 'Kassel',
  Kiel: 'Kiel',
  Koblenz: 'Koblenz',
  Krefeld: 'Krefeld',
  Leipzig: 'Leipzig',
  Leverkusen: 'Leverkusen',
  Lubeck: 'Lubeck',
  Ludwigshafen: 'Ludwigshafen',
  Magdeburg: 'Magdeburg',
  Mainz: 'Mainz',
  Mannheim: 'Mannheim',
  Moers: 'Moers',
  Monchengladbach: 'Monchengladbach',
  Mulheim: 'Mulheim',
  Munich: 'Munich',
  Munster: 'Munster',
  Neuss: 'Neuss',
  Nuremberg: 'Nuremberg',
  Oberhausen: 'Oberhausen',
  Offenbach: 'Offenbach',
  Oldenburg: 'Oldenburg',
  Osnabruck: 'Osnabruck',
  Paderborn: 'Paderborn',
  Pforzheim: 'Pforzheim',
  Potsdam: 'Potsdam',
  Recklinghausen: 'Recklinghausen',
  Regensburg: 'Regensburg',
  Remscheid: 'Remscheid',
  Reutlingen: 'Reutlingen',
  Rostock: 'Rostock',
  Saarbrucken: 'Saarbrucken',
  Salzgitter: 'Salzgitter',
  Siegen: 'Siegen',
  Solingen: 'Solingen',
  Stuttgart: 'Stuttgart',
  Trier: 'Trier',
  Ulm: 'Ulm',
  Wiesbaden: 'Wiesbaden',
  Wolfsburg: 'Wolfsburg',
  Wuppertal: 'Wuppertal',
  Wurzburg: 'Wurzburg',
  Herford: 'Herford',
} as const;

export const SpanishCity = {
  Madrid: 'Madrid',
  Barcelona: 'Barcelona',
} as const;

export const BritishCity = {
  London: 'London',
} as const;

export const PortugueseCity = {
  Lisbon: 'Lisbon',
} as const;

export type GermanCity = (typeof GermanCity)[keyof typeof GermanCity];
export type SpanishCity = (typeof SpanishCity)[keyof typeof SpanishCity];
export type BritishCity = (typeof BritishCity)[keyof typeof BritishCity];
export type PortugueseCity =
  (typeof PortugueseCity)[keyof typeof PortugueseCity];

export const WffCity = {
  ...GermanCity,
  ...SpanishCity,
  ...BritishCity,
  ...PortugueseCity,
} as const;

export type WffCity = (typeof WffCity)[keyof typeof WffCity];
