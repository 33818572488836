import { PropertyCard } from '@wff/ui/components/PropertyCard';
import { Property } from '@wff/types';
import { PropertyImage } from '@wff/types';
import { clx } from '@wff/ui/css';
import { feTrack } from '@wff/mithra/analytics/feTrack';
import { buildGoTo } from '@wff/mithra/config/platformRoutes';
import { wffTrackableEvents } from '@wff/mithra/analytics/wffTrackableEvents';

type PropertiesShowCaseSectionProps = {
  properties: {
    property: Property;
    images: PropertyImage[];
    coverImage: PropertyImage | undefined;
    badge: string;
    secondaryBadge?: string;
    amenitiesDetails: { icon: string; title: string; description: string }[];
  }[];
};

export const PropertiesShowCaseSection = ({
  properties,
}: PropertiesShowCaseSectionProps) => {
  return (
    <section
      data-aos="fade-down"
      data-aos-duration="3000"
      aria-labelledby="Reviews"
      className={
        'flex flex-col font-inter px-8 md:px-12 lg:px-36 py-16 items-center gap-y-12'
      }
    >
      <h1 className="text-2xl text-center lg:text-4xl font-bold text-primary">
        A glimpse into our properties
      </h1>
      <div
        className={clx(
          'w-full',
          'grid grid-rows-auto justify-center items-center',
          'grid-cols-[repeat(auto-fit,minmax(250px,1fr))]',
          'sm:grid-cols-[repeat(auto-fit,minmax(250px,1fr))]',
          'gap-x-3 min-[559px]:gap-y-3 gap-y-7'
        )}
      >
        {properties.map((property, index) => (
          <PropertyCard
            onClick={() => {
              const updatedUrl = buildGoTo(window.location.pathname, {
                search: { signup: 'started' },
                currentUrl: window.location.href,
              });
              window.history.pushState('signupStarted', '', updatedUrl);
              feTrack(wffTrackableEvents.flatSeeker.signupStarted);
            }}
            key={index}
            property={property.property}
            coverImage={property.coverImage || property.images[1]}
            badge={property.badge}
            secondaryBadge={property.secondaryBadge}
            amenitiesDetails={property.amenitiesDetails}
          />
        ))}
      </div>
    </section>
  );
};
